<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    :row-action="isSuperAdmin"
                    class="orders-table"
                    @onRowSelected="onRowSelected"
                >
                    <!-- createdAt -->
                    <template v-slot:[`item.createdAt`]="{ item }">
                        <identifier
                            :item="item"
                            :get-id="getIdFromIri"
                            :show-edit-link="isSuperAdmin || isCompanyAdmin"
                            :route-type="routeType"
                        ></identifier>
                        <warning
                            v-if="
                                isSuperAdmin &&
                                ((item.deliveryJob &&
                                    item.deliveryJob.warning) ||
                                    item.warning)
                            "
                            :item="item"
                            @execute="removeWarning(item.id)"
                        ></warning>
                        <v-tooltip v-if="item.deliverectError" bottom>
                            <template v-slot:activator="{ on }">
                                <v-chip
                                    class="rounded px-2 py-2 mb-1 white--text w-full"
                                    color="brandalt"
                                    small
                                    v-on="on"
                                >
                                    {{ $t('labels.deliverect_error') }}
                                </v-chip>
                            </template>
                            <span>{{ item.deliverectError }}</span>
                        </v-tooltip>
                    </template>

                    <!-- status -->
                    <template v-slot:[`item.status`]="{ item }">
                        <status :item="item"></status>
                    </template>

                    <!-- delivery -->
                    <template v-slot:[`item.deliveryJob`]="{ item }">
                        <delivery-status :item="item"></delivery-status>
                    </template>

                    <!-- deliveryJob.pickupTime -->
                    <template v-slot:[`item.deliveryJob.pickupTime`]="{ item }">
                        <router-link
                            v-if="isSuperAdmin"
                            :to="
                                getIdFromIri(
                                    item,
                                    'restaurant',
                                    routeType.RESTAURANT_EDIT,
                                    'id'
                                )
                            "
                        >
                            {{ item.restaurantName }}
                        </router-link>
                        <template v-else>
                            {{ item.restaurantName }}
                        </template>
                        <small
                            v-if="
                                item.deliveryJob &&
                                item.deliveryJob.primaryMethod !==
                                    'restaurant_managed'
                            "
                            class="d-flex flex-nowrap g-73--text"
                        >
                            {{ item.deliveryJob.pickupTime | transformDate }}
                        </small>
                        <third-party-warning :item="item"></third-party-warning>
                    </template>

                    <!-- dropOff -->
                    <template v-slot:[`item.requiredDeliveryTime`]="{ item }">
                        <drop-off :item="item"></drop-off>
                    </template>

                    <!-- deliveryName -->
                    <template v-slot:[`item.deliveryName`]="{ item }">
                        <customer :item="item"></customer>
                    </template>

                    <!-- priceTotal -->
                    <template v-slot:[`item.priceTotal`]="{ item }">
                        <payment :item="item"></payment>
                    </template>

                    <!-- sent -->
                    <template v-slot:[`item.sent`]="{ item }">
                        <flag
                            :active="parseFloat(item.sentRatio || 0) >= 1"
                        ></flag>
                    </template>

                    <!-- cost code -->
                    <template v-slot:[`item.costCode`]="{ item }">
                        <edit-cost-code
                            :item="item"
                            :api="api"
                            :disabled="!canEditExpenseCodes"
                            :update-list="updateTableItems"
                        ></edit-cost-code>
                    </template>

                    <template v-if="isSuperAdmin" v-slot:rowActions="{ item }">
                        <table-actions
                            :item="item"
                            :update-table-items="updateTableItems"
                        ></table-actions>
                    </template>
                </d-table>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import routeType from '@/router/routeType'
import { getIdFromIri } from '@/services/IdFromIri'
import EditInList from '@/components/mixins/EditInList'
import Status from '@/components/elements/Status'
import Customer from '@/components/elements/Customer'
import Payment from '@/components/elements/Payment'
import Warning from '@/components/elements/Warning'
import DeliveryStatus from '@/components/elements/DeliveryStatus.vue'
import Flag from '@/components/elements/Flag'
import DropOff from '@/components/elements/DropOff'
import OrderEvent from '@/pages/orders/mixins/OrderEvent'
import Headers from '@/pages/orders/mixins/Headers'
import ListFilters from '@/pages/orders/mixins/ListFilters'
import AuthMixin from '@/components/mixins/AuthMixin'
import TableActions from '@/components/pages/orders/TableActions'
import Identifier from '@/components/pages/orders/Identifier'
import EditCostCode from '@/components/pages/orders/EditCostCode'
import ThirdPartyWarning from '@/components/pages/orders/ThirdPartyWarning'

export default {
    components: {
        EditCostCode,
        Identifier,
        TableActions,
        DropOff,
        Flag,
        DeliveryStatus,
        Status,
        Customer,
        Payment,
        Warning,
        ThirdPartyWarning,
    },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        EditInList,
        OrderEvent,
        Headers,
        ListFilters,
        AuthMixin,
    ],
    data() {
        return {
            api: RestApiType.ORDERS,
            routeType: routeType,
            filters: {
                order: {
                    createdAt: 'desc',
                },
            },
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.orders')
        },
    },
    methods: {
        getIdFromIri,
        removeWarning(itemId) {
            RestApiCollection.get(this.api).removeDeliveryWarning(itemId)
        },
    },
}
</script>
