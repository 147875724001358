export default {
    getters: {
        IS_LOGGED_IN: 'auth_is_logged_in',
        GET_JWT: 'auth_get_jwt',
        GET_REFRESH_TOKEN: 'auth_get_refresh_token',
        KEEP_ME_SIGNED_IN: 'auth_keep_me_signed_in',
        GET_JWT_EXP_TIME: 'auth_get_jwt_exp_time',
        GET_EMAIL: 'auth_get_email',
        GET_ROLES: 'auth_get_roles',
        IS_RESTAURANT_ADMIN: 'is_restaurant_admin',
        IS_MULTI_RESTAURANT_ADMIN: 'is_multi_restaurant_admin',
        IS_COMPANY_ADMIN: 'auth_is_company_admin',
        IS_SUPER_ADMIN: 'auth_is_super_admin',
        MANAGED_COMPANIES: 'auth_managed_companies',
        MANAGED_RESTAURANTS: 'auth_managed_restaurants',
        ACTIVE_COMPANY: 'auth_active_company',
        ACTIVE_COMPANY_ID: 'auth_active_company_id',
        USER_ID: 'auth_user_id',
        AUTH_ERROR_HAPPENED: 'auth_error_happened',
        GET_NOTIFICATION_STATE: 'get_notification_state',
        NOTIFICATION_OFF_BY_USER: 'notification_off_by_user',
        NOTIFICATION_PERMISSION_GRANTED: 'notification_permission_granted',
        CUSTOM_EXPORT_EDIT_ENABLED: 'custom_export_edit_enabled',
    },
    mutations: {
        SET_JWT: 'auth_set_jwt',
        SET_REFRESH_TOKEN: 'auth_set_refresh_token',
        SET_KEEP_ME_SIGNED_IN: 'auth_set_keep_mee_signed_in',
        HAS_AUTH_ERROR_HAPPENED: 'auth_has_auth_error_happened',
        CLEAR_AUTH_ERROR: 'auth_clear_auth_error',
        LOGOUT: 'auth_logout',
        SET_NOTIFICATION_STATE: 'set_notification_state',
        SET_NOTIFICATIONS: 'set_notifications',
        SET_NOTIFICATION_PERMISSION: 'set_notification_permission',
    },
    actions: {
        SET_REFRESH_TOKEN: 'auth_set_refresh_token',
        LOGIN_WITH_JWT: 'auth_login_with_jwt',
        LOGIN_WITH_PASSWORD: 'auth_login_with_password',
        LOGIN_WITH_REFRESH_TOKEN: 'auth_login_with_refresh_token',
        LOGOUT: 'auth_logout',
        REGISTER_NOTIFICATIONS: 'register_notifications',
        UNREGISTER_NOTIFICATIONS: 'unregister_notifications',
        SET_NOTIFICATIONS: 'set_notifications',
        SET_NOTIFICATION_PERMISSION: 'set_notification_permission',
    },
}
