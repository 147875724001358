export default {
    ALLERGENS: 'allergens',
    ALLERGENS_ADDITIONAL: 'allergens-additional',
    ALLERGENS_SUITABILITY: 'allergens-suitability',
    COMPANY: 'companies',
    COMPANY_ADDRESS: 'company-addresses',
    COMPANY_TEAMS: 'company-teams',
    COMPANY_ALLOWANCE: 'company-allowances',
    COMPANY_ALLOWANCE_BALANCE_TYPES: 'company-allowance-balance-types',
    COMPANY_ALLOWANCE_TIME_UNITS: 'company-allowance-time-units',
    COMPANY_ALLOWANCE_ADDRESS_RESTRICTIONS:
        'company-allowance-address-restriction-type',
    COUNTRY: 'country',
    DELIVERY_METHOD: 'delivery-methods',
    DELIVERY_NOTIFICATION_TEMPLATE: 'delivery-notification-template',
    MEAL: 'meal',
    MEAL_CATEGORY: 'meal-category',
    MEAL_OPTION_GROUP: 'meal-option-group',
    ORDERING_SOFTWARE: 'ordering-software',
    ORDER_REFUND_BEHALF: 'refund-on-behalf',
    ORDER_STATUS: 'order-status',
    PAYMENT_METHODS: 'payment-methods',
    PROMOTION_DISCOUNT_TYPES: 'promotion-discount-types',
    PROMOTION_TYPES: 'promotion-types',
    RESTAURANT: 'restaurants',
    RESTAURANT_DELIVERY_AREA_TYPE: 'restaurant-delivery-area-types',
    RESTAURANT_PRICE_CATEGORIES: 'restaurant-price-category',
    RESTAURANT_TAGS: 'restaurant-tags',
    RESTAURANT_TAG_GROUPS: 'restaurant-tag-groups',
    SUITABLE_FOR_CONSTRAINTS: 'suitable-for-constraints',
    USER: 'users',
    USER_NOTIFICATIONS: 'user-notification-settings',
    VAT: 'vat',
    VEHICLE_TYPE: 'delivery-vehicle-types',
    RESIDENTIAL_CANTEEN_ADDRESS: 'residential-canteen-addresses',
    RESIDENTIAL_CANTEEN_RESTAURANT: 'residential-canteen-restaurants',
    USER_EXTRA_ROLES: 'extra-roles',
    RESTAURANT_QR_CODE_ON: 'restaurant-print-qr-code-on',
    RESTAURANT_THIRD_PARTY_TYPE: 'restaurant-third-party-type',
    CITY: 'city',
    DELIVERY_JOB_STATUS: 'delivery-job-status',
    TABLET_RESTAURANTS: 'tablet-restaurants',
    TABLET_USERS: 'tablet-users',
    RESTAURANT_PRINT_CONFIG: 'restaurant-print-config',
    RESTAURANT_CANTEEN_PRINT_CONFIG: 'restaurant-canteen-print-config',
    ORDER: 'order',
    CUSTOM_EXPORT_CATEGORY: 'custom-export-category',
    CUSTOM_EXPORT_PARAMETER_TYPE: 'custom-export-parameter-type',
}
